/* ----------------- React Native Paper --------------- */
import { Text } from "react-native-paper";
import type { VariantProp } from "react-native-paper/lib/typescript/components/Typography/types";

/* ----------------- Components --------------- */
import DonutChart from "../DonutChart";

/* ----------------- Types --------------- */
import type { Distribution } from "@memorang/types/src/content";

/* ----------------- Hooks --------------- */
import { useAppTheme } from "../../useAppTheme";
import { getProgressColorScale } from "../DonutChart/helpers";

type Props = {
	distribution: Distribution | number[];
	score: number;
	variant?: "progress" | "mastery";
	radius?: number;
	innerRadius?: number;
	textVariant?: VariantProp<Text>;
	colorScale?: string[];
};
const DonutScore = ({
	distribution,
	score,
	variant = "mastery",
	radius = 22,
	innerRadius = 18,
	textVariant,
	colorScale,
}: Props) => {
	const theme = useAppTheme();

	const finalColorScale =
		variant === "progress" ? colorScale : getProgressColorScale(score, theme);
	return (
		<DonutChart
			distribution={distribution}
			radius={radius}
			innerRadius={innerRadius}
			variant={variant}
			colorScale={finalColorScale}
			score={score}
		>
			<Text
				variant={textVariant ? textVariant : "bodySmall"}
			>{`${score}%`}</Text>
		</DonutChart>
	);
};

export default DonutScore;
