import { Platform } from "react-native";
/* -----------------Globals--------------- */
import { useSafeAreaInsets } from "react-native-safe-area-context";

/* -----------------UI--------------- */
import ReportCardsContainer from "./ReportCardsContainer";
import { SuggestedActionsChips } from "./SuggestedActionsChips";
import ReviewAnswersDialog, {
	type CheckboxStateMap,
} from "./dialogs/ReviewAnswersDialog";

/* -----------------Helpers & Hooks--------------- */
import { getReportData } from "../helpers/report-data";

/* -----------------Types--------------- */
import type { ReportDetails } from "@memorang/types/src/session/report";

/* -----------------Child components--------------- */

import { useAuthContext } from "@features/auth/contexts/AuthContext";
import { useExamContext } from "@features/exam/contexts/ExamContext";
import { currentAppVersion } from "@helpers/expo-extras";
import { useAppStore } from "@hooks/useAppStore";
import { useDevice } from "@hooks/useDevice";
import { StudySessionMasteryTagProgressCard } from "@memorang/ui/src/components/Cards/StudySessionMasteryTagProgressCard";
import { StudySessionPerformanceCard } from "@memorang/ui/src/components/Cards/StudySessionPerformanceCard";
import StudySessionSummaryCard from "@memorang/ui/src/components/Cards/StudySessionSummaryCard";
import ScrollContainer from "@memorang/ui/src/components/ScrollContainer";
import UpSaleCard from "./UpSaleCard";

import { events } from "@constants/tracking";
import { useTagsStore } from "@features/dashboard/hooks/useTagsStore";
import { getProgressColorScale } from "@helpers/content/distribution";
import type { TagItem } from "@helpers/content/types";
import { formatDate } from "@helpers/date";
import { isWeb } from "@helpers/platform";
import { useAppTheme } from "@hooks/useAppTheme";
import { SessionAnalysis } from "@memorang/features/src/session-analysis/SessionAnalysis";
import { trackCustomEvent } from "analytics";
import { useLocalSearchParams, useRouter } from "expo-router";
import { useProductAccess } from "../hooks/useProductAccess";

type Props = {
	reportDetails: ReportDetails;
	showReviewDialog: boolean;
	handleContinue: () => void;
	handleClickActionChip: (action: string) => void;
	checkboxStateMap: CheckboxStateMap;
	handleChangeCheckboxStateMap: (key: keyof CheckboxStateMap) => void;
	handleReviewButtonClick: () => void;
	handleCloseReviewDialog: () => void;
	selectedItemTypeForReview?: "QUESTION";
	handleReview: (checkboxStateMap: CheckboxStateMap) => void;
	fetchingReviewItems: boolean;
	isSummativeTest?: boolean;
	handleUpdateIncorrectCheckboxStateMap: () => void;
	handleTracking: (
		eventName: string,
		params?: Record<string, string | number>,
	) => void;
};
export const ReportView = ({
	reportDetails,
	showReviewDialog,
	handleContinue,
	handleClickActionChip,
	checkboxStateMap,
	isSummativeTest,
	handleChangeCheckboxStateMap,
	handleReviewButtonClick,
	handleCloseReviewDialog,
	selectedItemTypeForReview,
	handleReview,
	fetchingReviewItems,
	handleTracking,
	handleUpdateIncorrectCheckboxStateMap,
}: Props) => {
	const {
		summaryCardData,
		masteryCardData,
		masteryTagProgressData,
		reviewListItemsData,
		sessionId,
	} = getReportData(reportDetails, isSummativeTest);

	const showFullReport = !reportDetails?.sessionSummaryConfig?.length;

	const { userDetails } = useAuthContext();

	const { id } = useLocalSearchParams<{
		id: string;
	}>();

	const {
		currentExamContext: { examName },
	} = useExamContext();

	const { hasPracticeTestAccess, hasStudyPackAccess, showStudyPackViews } =
		useProductAccess();

	const { isMobile } = useDevice();

	const companyName = useAppStore((store) => store.companyName);
	const appName = useAppStore((store) => store.app.name);
	const scoreRanges = useAppStore((store) => store.app.scoreRanges);
	const tenantName = useAppStore((store) => store.tenant.name);
	const reportView = useAppStore((store) => store.reportView);

	const reviewEnabled = reportView?.practiceTest?.reviewEnabled;

	const userName = userDetails?.name!;
	const email = userDetails?.email;
	const theme = useAppTheme();

	const colorScale = getProgressColorScale(
		summaryCardData?.score,
		theme,
		scoreRanges!,
	);

	const setCurrentSelectedMasteryItems = useTagsStore(
		(state) => state.setCurrentSelectedMasteryItems,
	);
	const productName = `${examName} ${
		isSummativeTest ? "Practice Tests" : "Study-Pack"
	}`;

	const insets = useSafeAreaInsets();

	const router = useRouter();

	const selectedReviewListItemsData =
		reviewListItemsData?.[selectedItemTypeForReview!];

	const handleClickCard = (items: TagItem[], title: string) => {
		trackCustomEvent({
			eventName: events.sessionReportCardClicked,
			sessionId: sessionId,
			type: title,
			examName: reportDetails?.examName,
		});
		setCurrentSelectedMasteryItems(items);
		router.push({
			pathname: "/(protected)/session/[id]/report/detailed-list/mastery",
			params: {
				type: title,
				id,
				examName: reportDetails?.examName,
			},
		});
	};
	const masteryTagsCards = masteryTagProgressData?.map((item) => {
		return (
			<StudySessionMasteryTagProgressCard
				handleClickCard={() => handleClickCard(item.items, item.title)}
				key={item.title}
				{...item}
			/>
		);
	});

	const getReviewEnabled = () => {
		switch (reportDetails?.reportSessionType) {
			case "DIAGNOSTIC":
				return isWeb ? true : !isMobile;
			case "PRACTICETEST":
				return reviewEnabled && (isWeb ? true : !isMobile);
			case "STUDYPACK":
				return true;
			default:
				return false;
		}
	};
	const showReview = getReviewEnabled();

	const cards = [
		// biome-ignore lint/correctness/useJsxKeyInIterable: // TODO fix me later
		<StudySessionSummaryCard
			{...summaryCardData}
			handleContinue={handleContinue}
			scoreRanges={scoreRanges!}
			isSummativeTest={isSummativeTest}
			colorScale={colorScale}
		/>,
		...(showFullReport
			? [
					// biome-ignore lint/correctness/useJsxKeyInIterable: // TODO fix me later
					<SuggestedActionsChips
						showReview={showReview}
						showStudy={showStudyPackViews}
						handleClickActionChip={handleClickActionChip}
					/>,
				]
			: []),

		...(isSummativeTest && isWeb
			? [
					// biome-ignore lint/correctness/useJsxKeyInIterable: // TODO fix me later
					<SessionAnalysis
						sessionId={sessionId}
						context={examName}
						handleTracking={handleTracking}
						sessionMetadata={{
							sessionTitle: reportDetails.examName,
							sessionDate: formatDate(reportDetails.sessionDate),
							numItems: reportDetails.numQuestions,
						}}
						feedbackDialogParams={{
							currentAppVersion,
							userName,
							email,
							product: productName,
							appName,
							platform: Platform.OS.toUpperCase(),
							view: "Session Report",
							tenantId: tenantName,
							context: examName,
							feature: "AI Session Analysis",
							sessionId,
							companyName,
						}}
					/>,
				]
			: []),
		...(showFullReport
			? [
					// biome-ignore lint/correctness/useJsxKeyInIterable: // TODO fix me later
					<UpSaleCard
						hasPracticeTestAccess={hasPracticeTestAccess}
						hasStudyPackAccess={hasStudyPackAccess}
						showStudyPack={showStudyPackViews}
					/>,
				]
			: []),
		...(masteryCardData && showFullReport
			? [
					// biome-ignore lint/correctness/useJsxKeyInIterable: // TODO fix me later
					<StudySessionPerformanceCard
						checkboxStateMap={checkboxStateMap}
						handleChangeCheckboxStateMap={handleChangeCheckboxStateMap}
						{...masteryCardData}
						handleReviewButtonClick={handleReviewButtonClick}
						showReview={showReview}
					/>,
				]
			: []),
		...masteryTagsCards,
	];
	return (
		<ScrollContainer
			contentContainerStyle={{
				paddingTop: 48,
				paddingBottom: 88 + insets.bottom,
				paddingHorizontal: 16,
				gap: 16,
			}}
		>
			<ReportCardsContainer cards={cards} />
			{showReviewDialog && (
				<ReviewAnswersDialog
					open={showReviewDialog}
					handleChangeCheckboxStateMap={handleChangeCheckboxStateMap}
					checkboxStateMap={checkboxStateMap}
					sections={selectedReviewListItemsData?.items?.sections!}
					handleClose={handleCloseReviewDialog}
					handleReview={handleReview}
					loading={fetchingReviewItems}
					numIncorrect={selectedReviewListItemsData?.numIncorrect}
					handleUpdateIncorrectCheckboxStateMap={
						handleUpdateIncorrectCheckboxStateMap
					}
				/>
			)}
		</ScrollContainer>
	);
};
