import { defaultMasteryLabels } from "@constants/common";
import { useAppStore } from "@hooks/useAppStore";
import type { AppTheme } from "@hooks/useAppTheme";
import type { Distribution } from "@memorang/types/src/content";
import type { ScoreRange } from "@memorang/ui/src/types";
import type { Item } from "types/tag";

const calculateRequiredDist = (items: Item[]) => {
	return items.reduce(
		(a, { distribution }) => {
			const numCurrentTotalRequiredItems = Object.keys(a).reduce(
				(p, i) => p + a[i as keyof Distribution],
				0,
			);
			if (numCurrentTotalRequiredItems < 50) {
				a[distribution] = a[distribution] ? a[distribution] + 1 : 1;
			}
			return a;
		},
		{
			low: 0,
			none: 0,
			high: 0,
			medium: 0,
		},
	);
};

const getMasteryLabels = () => {
	const masteryLabels = useAppStore.getState().app.masteryLabels;
	return masteryLabels || defaultMasteryLabels;
};

export const getProgressColorScale = (
	score: number,
	theme: AppTheme,
	scoreRanges: ScoreRange[],
) => {
	const { high, low, none: empty, medium } = theme.colors.progress;
	const lowScoreRange = scoreRanges[0].rangeArray[1];
	const mediumScoreRange = scoreRanges[1].rangeArray[1];
	if (score <= lowScoreRange) {
		return [low, empty];
	}
	if (score <= mediumScoreRange) {
		return [medium, empty];
	}
	return [high, empty];
};
export { calculateRequiredDist, getMasteryLabels };
